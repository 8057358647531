/*@media print {*/
/*  #printTarget {*/
/*    margin-top: 0 !important;*/
/*    padding-top: 0 !important;*/
/*  }*/
/*  !*.no-page-break {*!*/
/*  !*    page-break-inside: avoid;*!*/
/*  !*    break-inside: avoid;*!*/
/*  !*}*!*/
/*  !*body {*!*/
/*  !*    width: 100%;*!*/
/*  !*    height: 100%;*!*/
/*  !*    margin: 0;*!*/
/*  !*    padding: 0;*!*/
/*  !*    line-height: 1.45;*!*/
/*  !*    font-family: Garamond, "Times New Roman", serif;*!*/
/*  !*    color: #333;*!*/
/*  !*    position: absolute;*!*/

/*  !*}*!*/
/*  body {*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    line-height: 1.45;*/
/*    font-family: Garamond, 'Times New Roman', serif;*/
/*    color: #333;*/
/*    position: relative;*/
/*  }*/

/*    @page {*/
/*        size: A4 landscape;*/
/*        margin: 0;*/
/*    }*/
/*}*/
/*@media print {*/
/*    body, html {*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        margin: 0;*/
/*        padding: 0;*/
/*    }*/
/*    !*#printTarget {*!*/
/*    !*    margin: 0 !important;*!*/
/*    !*    padding: 0 !important;*!*/
/*    !*    overflow: visible !important;*!*/
/*    !*    zoom: 50%;*!*/
/*    !*}*!*/
/*    @page {*/
/*        size: A4 landscape;*/
/*        margin: 0;*/
/*    }*/
/*}*/

@media print {
  body * {
    visibility: hidden;
  }
  /* #printTarget 내의 모든 요소를 보이게 함 */
  #printTarget,
  #printTarget * {
    visibility: visible;
  }
  /* #printTarget을 페이지 상단에 위치시킴 */
  #printTarget {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0 !important;
    padding: 0 !important;
  }
}
