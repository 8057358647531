
.join-body {
    width: 100%;
    height: 100%;
    position: relative;
}
.join-header {
    margin: 40px
}
.join-body::before {
    content: "";
    background-image: url("./../../assets/images/background.png") ;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.2;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
}

.join-content {
    width: 100%;
    padding-left: 25%;
    padding-right: 25%;
}
.join-content-m {
    width: 100%;
    padding-left: 2%;
    padding-right: 2%;
}
div::-webkit-scrollbar {
    width: 12px;  /* width of the vertical scrollbar */
    height: 12px; /* height of the horizontal scrollbar */
}

div::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

div::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}
.all-agree{
    text-align: center;
    border: 1px solid #eaeaea;
    background-color: #fbfbfb;
    padding: 10px;
    margin: 20px 0 0 0;
}
