.wrap-bg {
    width: 100%;
    height: 100%;
    position: relative;
}

.wrap-bg::before {
    content: "";
    background-image: url("../images/new-login-bg.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.m-wrap-bg {
    width: 100%;
    height: 100%;
    position: relative;
}

.m-wrap-bg::before {
    content: "";
    background-image: url("../images/new-login-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.6;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: -1;
}

.login-bg-gradient {
    background: linear-gradient(-45deg,  #71ace0,#61bae1, #2796f1, #6f94bd, #61bae1);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
    border-radius: 20px;
    box-shadow: 0px 0px 20px black
}

@keyframes gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}


