.ai-text-speech {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5em;
    z-index: 1000;
}

.loading-circle {
    width:30px;
    height:30px;
    background-color: #fff;
    border-radius:50%;
    animation: move 2s cubic-bezier(.41,.83,.63,.24) infinite;
    display: inline-block;
}
@keyframes move {
    0% {
        transform: translateX(0) scale(0.5);
    }
    25% {
        transform: translateX(-80px) scale(1);
    }
    50% {
        transform: translateX(0) scale(0.5);
    }
    75% {
        transform: translateX(80px) scale(1);
    }
    100% {
        transform: translateX(0) scale(0.5);
    }

}
