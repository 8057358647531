/*.imageEditContainer {*/
/*    position: relative;*/
/*}*/

/*#imageEdit, #c19 {*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: 0;*/
/*}*/

/*#imageEdit {*/
/*    z-index: 1;*/
/*    pointer-events: none;*/
/*}*/

/*#c19 {*/
/*    z-index: 2;*/
/*    pointer-events: auto;*/
/*}*/

.imageCenter{
    text-align: -webkit-center;
}