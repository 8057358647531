.chart-content {
    /*max-width: 2560px;*/
    margin: 1rem 0 1rem 0;
}

.flex-box {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    justify-content: center;
}

.flex-item {
    min-height: 100px;
    flex-basis: 150px;
    flex-grow: 0;
}

/*@media (max-width: 1600px) {*/
/*    .chart-section {*/
/*        flex-direction: column;*/
/*    }*/
/*}*/

.chart-header {
    margin: 0.5rem;
    font-size: 1rem;
    align-self: center;
    width: 130px;
}

.chart-quick-go {
    margin: 0.5rem;
    font-size: 0.94rem;
    font-weight: bold;
    align-self: center;
    color: #da5a09;
}
.dashboard-bg-gradient {
    background: linear-gradient(-50deg, #87CEEB, #007BA7, #2168b7, #B0E0E6);
    background-size: 500% 500%;
    /*background-size: cover;*/
    animation: gradient 5s ease infinite;
    /*border-radius: 20px;*/
    /*box-shadow: 0px 0px 20px black;*/
}


@keyframes gradient {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}


