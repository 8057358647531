.dynamic-font-size {
  font-size: 22px;
}

@media (max-width: 1955px) {
  .dynamic-font-size {
    font-size: 21px;
  }
}
@media (max-width: 1894px) {
  .dynamic-font-size {
    font-size: 20.3px;
  }
}
@media (max-width: 1894px) {
  .dynamic-font-size {
    font-size: 18.5px;
  }
}
@media (max-width: 1722px) {
  .dynamic-font-size {
    font-size: 16px;
  }
}

@media (max-width: 1590px) {
  .dynamic-font-size {
    font-size: 15px;
  }
}

@media (max-width: 1541px) {
  .dynamic-font-size {
    font-size: 14px;
  }
}

.header-dynamic-font-size {
  font-size: 15px;
}

@media (max-width: 1465px) {
  .header-dynamic-font-size {
    font-size: 14.2px;
  }
}
@media (max-width: 1432px) {
  .header-dynamic-font-size {
    font-size: 13px;
  }
}
@media (max-width: 1390px) {
  .header-dynamic-font-size {
    font-size:12px;
  }
}
@media (max-width: 1344px) {
  .header-dynamic-font-size {
    font-size: 11px;
  }
}

/*@media (max-width: 1590px) {*/
/*  .header-dynamic-font-size {*/
/*    font-size: 15px;*/
/*  }*/
/*}*/

/*@media (max-width: 1541px) {*/
/*  .header-dynamic-font-size {*/
/*    font-size: 14px;*/
/*  }*/
/*}*/

