.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #91b3cb;
    border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #3ba1ea;
  border-radius: 10px;
}


.batch-scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 20px;
}
.batch-scrollbar::-webkit-scrollbar-thumb {
    background: #014985;
    border-radius: 10px;
}
.batch-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #3ba1ea;
    border-radius: 10px;
}