.gradient-text {
    background-color: #dc5051;
    background-image: linear-gradient(
            45deg,
            #63b5ff 16.6666%,
            #ffb738 16.6666%,
            #ffb738 33.333%,
            #63b5ff 33.333%,
            #63b5ff 50%,
            #ffb738 50%,
            #ffb738 66.666%,
            #63b5ff 66.666%,
            #63b5ff 83.333%,
            #ffb738 83.333%
    );
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: text-animation-r 0.65s ease forwards;
}

.gradient-text:hover {
    animation: text-animation 0.45s ease-in forwards;
}

@keyframes text-animation-r {
    0% {
        background-size: 650%;
    }
    40% {
        background-size: 650%;
    }
    100% {
        background-size: 100%;
    }
}

@keyframes text-animation {
    0% {
        background-size: 100%;
    }
    80% {
        background-size: 650%;
    }
    100% {
        background-size: 650%;
    }
}

.body-404 {
    /*background-color: #3498db7a;*/
    width: 100%;
    height: 100vh;
    background: #f5f8fa;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}


h1 {
    /*font-family: "Archivo Black", sans-serif;*/
    font-weight: bold;
    font-size: 9.5em;
    text-align: center;
    /*margin-bottom: -0.25em;*/
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    /*width: 800px;*/
    position: relative;
}

.ball {
    animation: float 3.5s ease-in-out infinite;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    position: relative;
    background: radial-gradient(circle at 70% 30%, white 3px, #3b9bf1 20%, #48b9e8 80%, #ffb738 100%);
    box-shadow: inset 0 0 20px #aac2c6, inset 40px 0 30px #ffb738, inset 88px 0px 60px #9ec8df,
    inset -20px -60px 100px #e9fcfd, inset 0 50px 140px #e9fdf8, 0 0 90px #fff;
    cursor: pointer;
}

.ball .button-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.2em;
    font-weight: bold;
    color: #fff;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: "Archivo Black", sans-serif;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-80px);
    }
    100% {
        transform: translateY(0px);
    }
}

.shadow {
    background: #9dcef1;
    width: 150px;
    height: 40px;
    top: 70%;
    animation: expand 3.5s infinite;
    border-radius: 50%;
    position: absolute;

}

@keyframes expand {
    0%, 100% {
        transform: scale(0.5);

    }
    50% {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    h1 {
        font-size: 5em;
    }

    .ball {
        animation: float 3.5s ease-in-out infinite;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        position: relative;
        background: radial-gradient(circle at 75% 30%, white 3px, #3b9bf1 2%, #48b9e8 60%, #ffb738 100%);
        box-shadow: inset 0 0 5px #aac2c6, inset 10px 0 15px #ffb738, inset 44px 0px 30px #9ec8df,
        inset -30px -30px 50px #e9fcfd, inset 0 25px 70px #e9fdf8, 0 0 45px #fff;
        cursor: pointer;
    }

    .ball .button-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.7em;
        font-weight: bold;
        color: #fff;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "Archivo Black", sans-serif;
    }

    .shadow {
        background: #9dcef1;
        width: 100px;
        height: 32px;
        top: 64%;
        animation: expand 3.5s infinite;
        border-radius: 50%;
        position: absolute;

    }

}

@media (max-width: 380px) {
    h1 {
        font-size: 5em;
    }

    .ball {
        animation: float 3.5s ease-in-out infinite;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        position: relative;
        background: radial-gradient(circle at 75% 30%, white 3px, #3b9bf1 2%, #48b9e8 60%, #ffb738 100%);
        box-shadow: inset 0 0 5px #aac2c6, inset 10px 0 15px #ffb738, inset 44px 0px 30px #9ec8df,
        inset -30px -30px 50px #e9fcfd, inset 0 25px 70px #e9fdf8, 0 0 45px #fff;
        cursor: pointer;
    }

    .ball .button-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.2em;
        font-weight: bold;
        color: #fff;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: "Archivo Black", sans-serif;
    }

    .shadow {
        background: #9dcef1;
        width: 80px;
        height: 24px;
        top: 69%;
        animation: expand 3.5s infinite;
        border-radius: 50%;
        position: absolute;

    }

}




